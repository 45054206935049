
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
import Api from '@/api/Api'
import { ApiTopWordSpeed } from '@/types'

export default defineComponent({
  name: 'Leaderboards',
  components: {
    NavBar
  },
  data () {
    return {
      users: new Map<number, ApiTopWordSpeed[]>(),
      sort: this.$route.query.sort as string,
      page: 0,
      end: false
    }
  },
  mounted () {
    this.load()

    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    usersAsArray () {
      let res: ApiTopWordSpeed[] = []
      let i = 0
      while (true) {
        const batch = this.users.get(i)
        if (batch) {
          res = res.concat(batch)
        } else {
          return res
        }
        i++
      }
    }
  },
  methods: {
    load (page?: number) {
      if (this.end) {
        return
      }
      if (page) {
        Api.getTopWordSpeed(100, page).then(data => {
          if (data.length === 0) {
            this.end = true
          } else {
            this.users.set(page, data)
            this.page = page
          }
        })
      } else {
        Api.getTopWordSpeed(100, 0).then(data => {
          this.users.clear()
          this.users.set(0, data)
          this.page = 0
          this.end = false
        })
      }
    },
    onScroll () {
      if (window.scrollY + window.innerHeight > document.body.scrollHeight - 300) {
        this.load(this.page + 1)
      }
    }
  }
})
